import classNames from 'classnames';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Header from '../components/header';
import Image from '../components/image';
import Layout from '../components/layout';
import Link from '../components/link';
import Markdown from '../components/markdown';
import SEO from '../components/SEO';
import SocialLinks from '../components/social-links';
import { removeDuplicates } from '../helpers';
import SectionOpener from '../sections/section-opener/section-opener';
import { resolve } from '../urls';

class SinglePersonPage extends Component {
  render() {
    const { data, location } = this.props;
    const { contentfulPersons: page } = data;

    // Extract Insight articles written by this Person
    let writings;
    let filteredWritings;

    if (page.magazine) {
      // filter out anything that Person is not the author of
      writings = page.magazine.filter((article) => {
        if (article.authors) {
          for (let i = 0; i < article.authors.length; i++) {
            if (article.authors[i].name === page.name) {
              return true;
            }
          }
        }
        return false;
      });

      // remove any duplicates, if Person happened to be both the author
      // and refrenced as a contact in the article
      filteredWritings = removeDuplicates(writings, 'slug');

      // sort the publish dates (most recent first)
      filteredWritings.sort((a, b) => {
        a = new Date(a.published_date);
        b = new Date(b.published_date);
        return a > b ? -1 : a < b ? 1 : 0;
      });
    }

    const selectedName = page.shortName ? page.shortName : page.name;

    const pageWrapClasses = classNames({
      'page': true,
      'page--person': true, // just to avoid the /us, /nl etc
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          <SEO
            title={page.name}
            description="Learn more about the people behind Edenspiekermann"
            shareImage={getSrc(page.avatar)}
            metadata={page.metadata}
          />
          <SectionOpener openerType="person">
            <div className="person__img-content-wrap">
              <div className="person__img-wrap">
                {page.avatar && (
                  <Image className="person__img" image={page.avatar} alt={page.name} />
                )}
                <SocialLinks
                  facebookUrl={page.facebook}
                  instagramUrl={page.instagram}
                  linkedinUrl={page.linkedin}
                  twitterUrl={page.twitter}
                />
              </div>
              <div className="person__content-wrap">
                <Header heading={page.name} label="Our People" />
                <div className="person__position">{page.position}</div>
                <p>Edenspiekermann</p>
                {page.office && (
                  <Markdown markdown={page.office.fullAddress.childMarkdownRemark.html} />
                )}
                <a className="button-icon" href={`mailto:${page.email}`}>
                  {`Email ${selectedName}`}
                </a>
              </div>
            </div>
          </SectionOpener>
          {filteredWritings && filteredWritings.length > 0 && (
            <div className="person__writings">
              <div className="label">{`Read what ${selectedName} wrote`}</div>
              <div className="magazine-teaser-list">
                {filteredWritings.slice(0, 3).map((article, i) => (
                  <div className="magazine-teaser-list__item" key={i}>
                    <article className="magazine-teaser">
                      <Link
                        to={resolve('insights:detail', {
                          insightSlug: article.slug,
                        })}
                        className="magazine-teaser__link"
                      >
                        <div className="magazine-teaser__image-wrapper">
                          {article.main_image && (
                            <div className="magazine-teaser__image">
                              <Image image={article.main_image} alt={article.main_image.title} />
                            </div>
                          )}
                        </div>
                        <div className="magazine-teaser__text">
                          {article.post_category && (
                            <span className="magazine-teaser__category">
                              {article.post_category}
                            </span>
                          )}

                          {article.title && (
                            <span className="magazine-teaser__title" itemProp="name">
                              {article.title}
                            </span>
                          )}
                          {article.teaser && (
                            <p className="magazine-teaser__subline" itemProp="description">
                              {article.teaser.teaser}
                            </p>
                          )}
                        </div>
                      </Link>
                    </article>
                  </div>
                ))}
              </div>
              <div className="pagination">
                <div className="pagination__option" />
                <div className="pagination__option">
                  <Link
                    to={resolve('insights:home')}
                    className="button-icon button-icon--right magazine-tease__view-more-link"
                  >
                    View more articles
                  </Link>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </div>
    );
  }
}

export default SinglePersonPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulPersons(slug: { eq: $slug }) {
      id
      name
      shortName
      slug
      avatar {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 500, aspectRatio: 1)
      }
      email
      position
      twitter
      facebook
      linkedin
      instagram
      active_employee
      office {
        fullAddress {
          fullAddress
          childMarkdownRemark {
            html
          }
        }
      }
      magazine {
        title
        slug
        post_category
        published_date
        teaser {
          teaser
          childMarkdownRemark {
            html
          }
        }
        main_image {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 500)
        }
        authors {
          name
        }
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

SinglePersonPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
